<template>
  <v-dialog v-model="dialog.display" max-width="500">
    <v-card>
      <v-card-title>
        <div class="text-subtitle-1 font-weight-bold">
          {{ $t("account.profile.avatar.title") }}
        </div>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="mt-4">
        <div class="d-flex justify-center align-center">
          <img ref="avatar" width="500" :src="file.url" />
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="cancel()" text>{{ $t("btn.cancel") }}</v-btn>
        <v-btn @click="save()" color="primary" text>{{ $t("btn.save") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Cropper from "cropperjs";
export default {
  props: {
    dialog: {
      type: Object,
      default: function () {
        return {
          display: false,
        };
      },
    },
    file: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data: () => ({
    cropper: false,
  }),
  watch: {
    "dialog.display"(value) {
      if (value) {
        this.$nextTick(function () {
          if (!this.$refs.avatar) {
            return;
          }
          let cropper = new Cropper(this.$refs.avatar, {
            aspectRatio: 1 / 1,
            viewMode: 1,
          });
          this.cropper = cropper;
        });
      } else {
        if (this.cropper) {
          this.cropper.destroy();
          this.cropper = false;
        }
      }
    },
  },
  methods: {
    cancel() {
      this.$emit("cancel");
      this.dialog.display = false;
    },
    save() {
      let binStr = atob(
        this.cropper.getCroppedCanvas().toDataURL(this.file.type).split(",")[1]
      );
      let arr = new Uint8Array(binStr.length);
      for (let i = 0; i < binStr.length; i++) {
        arr[i] = binStr.charCodeAt(i);
      }
      const file = new File([arr], this.file.name, { type: this.file.type });
      this.$emit("edit", { id: this.file.id, file: file });
      this.dialog.display = false;
    },
  },
};
</script>