<template>
  <div>
    <account></account>
  </div>
</template>


<script>
import Account from './../../components/account/index';
export default {
  data: () => ({}),
  metaInfo() {
    return {
      title: this.$i18n.t('account.title'),
    };
  },
  components: {
    Account
  }
}
</script>