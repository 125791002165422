<template>
  <div>
    <div class="font-weight-medium text-h6 mb-3">
      {{ $i18n.t("account.title") }}
    </div>
    <v-card class="rounded-md mb-4">
      <v-tabs
        height="55"
        show-arrows
        slider-size="3"
        v-model="tab"
        class="elevation-2 rounded-lg"
      >
        <v-tabs-slider color="primary"></v-tabs-slider>
        <v-tab
          class="font-weight-bold text-body-1"
          :to="$i18n.getRouteLink('account')"
        >
          <v-icon
            :class="{
              'primary--text': tab === '/account/',
              'black--text': tab !== '/account/',
            }"
            left
            >mdi-account-tie</v-icon
          >
          <span
            :class="{
              'primary--text': tab === '/account/',
              'black--text': tab !== '/account/',
            }"
            class="secondary-font"
            >{{ $t("menu.account.profile") }}</span
          >
        </v-tab>
        <v-tab
          :to="$i18n.getRouteLink('api')"
          class="font-weight-bold text-body-1"
          v-if="$auth.check('ROLE_CLIENT')"
        >
          <v-icon
            :class="{
              'primary--text': tab === '/account/api/',
              'black--text': tab !== '/account/api/',
            }"
            left
            >mdi-cog-outline</v-icon
          >
          <span
            :class="{
              'primary--text': tab === '/account/api/',
              'black--text': tab !== '/account/api/',
            }"
            class="secondary-font"
            >{{ $t("menu.account.api") }}</span
          >
        </v-tab>
        <v-tab
          :to="$i18n.getRouteLink('security')"
          class="font-weight-bold text-body-1"
        >
          <v-icon
            :class="{
              'primary--text': tab === '/account/security/',
              'black--text': tab !== '/account/security/',
            }"
            left
            >mdi-shield-account-outline</v-icon
          >
          <span
            :class="{
              'primary--text': tab === '/account/security/',
              'black--text': tab !== '/account/security/',
            }"
            class="secondary-font"
            >{{ $t("menu.account.security") }}</span
          >
        </v-tab>
      </v-tabs>
    </v-card>
    <v-row>
      <v-col cols="12" sm="5">
        <v-card class="pt-5 pb-2">
          <v-card-text>
            <div
              class="d-flex align-center justify-center flex-column flex-wrap"
            >
              <v-avatar
                color="grey lighten-3"
                :src="require('@/assets/img/user.png')"
                size="100"
              >
                <img v-if="files.length" :src="files[0].url" />
                <img
                  v-else-if="$auth.user().profile.avatar"
                  :src="
                    $utils.getUrlFile($auth.user().profile.avatar.path.medium)
                  "
                />
                <img
                  v-else
                  :src="require('@/assets/img/user.png')"
                  alt="John"
                />
              </v-avatar>
              <file-upload
                :custom-action="updateAvatar"
                :extensions="upload.extension"
                :accept="upload.accept"
                name="avatar"
                :size="upload.size"
                :drop="upload.drop"
                v-model="files"
                :multiple="upload.multiple"
                ref="upload"
                @input-filter="inputFilter"
                @input-file="inputFile"
                style="cursor: pointer"
              >
                <div
                  class="d-flex danger--text justify-center font-weight-bold"
                  v-if="isFileError"
                >
                  {{ getAvatarErrors(files[0]) }}
                </div>
                <div class="d-flex flex-wrap justify-center align-center mb-1">
                  <v-btn
                    color="primary"
                    text
                    @click="$refs.upload.active = true"
                    class="mr-0 mt-3 font-weight-bold"
                    >{{ $t("account.btn.upload_avatar") }}</v-btn
                  >
                </div>
              </file-upload>
            </div>
            <v-list class="mt-2">
              <v-list-item class="px-0" v-if="this.$auth.user().lastLogin">
                <v-list-item-content>
                  <div class="d-flex align-center">
                    <v-list-item-title class="text-body-1 font-weight-bold">
                      <v-icon left>mdi-clock-outline</v-icon>
                      {{ $t("user.fields.last_update.title") }}
                    </v-list-item-title>
                    <v-list-item-subtitle class="ml-auto text-right">
                      <span class="text-body-1">
                        {{
                          $moment(this.$auth.user().updateAt).format(
                            "YYYY-MM-DD HH:mm:ss"
                          )
                        }}
                      </span>
                    </v-list-item-subtitle>
                  </div>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="7">
        <router-view></router-view>
      </v-col>
    </v-row>
    <template v-if="files.length">
      <avatar-edit
        :file="files[0]"
        :dialog="dialog.avatar"
        @edit="editAvatar"
        @cancel="cancelAvatar"
      ></avatar-edit>
    </template>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import AvatarEdit from "./avatar";
import FileUpload from "vue-upload-component";
export default {
  data: () => ({
    tab: null,
    upload: {
      extension: "gif,jpg,jpeg,png",
      accept: "image/png,image/gif,image/jpeg",
      multiple: false,
      drop: false,
      size: 1024 * 512,
    },
    loading: {
      upload: false,
    },
    progress: 0,
    dialog: {
      avatar: {
        display: false,
      },
    },
    files: [],
    cropper: false,
    avatar: null,
  }),
  methods: {
    async updateAvatar(file) {
      const data = new FormData();
      data.append("avatar", file.file);

      try {
        await this.request({
          url: "/api/profiles/avatar",
          method: "post",
          data: data,
          headers: { "Content-Type": "multipart/form-data" },
          messages: {
            500: true,
            200: this.$t("account.profile.avatar.success"),
          },
        });
        this.$auth.fetch();
      } catch (error) {
        // empty
      }
    },
    inputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        // hide alert message
        this.notify({ status: false });
        if (!/\.(png|jpeg|jpg|gif|webp)$/i.test(newFile.name)) {
          this.$refs.upload.update(newFile, { error: "extension" });
          return prevent();
        }
      }

      if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
        // Create a blob field
        newFile.blob = "";
        let URL = window.URL || window.webkitURL;
        if (URL && URL.createObjectURL) {
          newFile.url = URL.createObjectURL(newFile.file);
        }
      }
    },
    // add, update, remove File Event
    inputFile(newFile, oldFile) {
      if (newFile && !oldFile) {
        this.avatar = null;
        this.$nextTick(function() {
          this.dialog.avatar.display = true;
        });
      }

      if (newFile && oldFile) {
        // reset progress
        this.progress = 0;
        // update
        if (newFile.active && !oldFile.active) {
          // beforeSend
          // min size
          if (newFile.size >= 0 && newFile.size < this.minSize) {
            this.$refs.upload.update(newFile, {
              error: "minSize",
            });
          }
        }
        if (newFile.error && !oldFile.error) {
          // error
          const message = this.getAvatarErrors(newFile);
          if (message) {
            this.notify({ message: message });
          }
        }
      }

      if (!newFile && oldFile) {
        this.dialog.display = false;
      }
    },
    getAvatarErrors(file) {
      let message;
      switch (file.error) {
        case "minSize":
          message = this.$t("account.fields.avatar.minSize");
          break;
        case "size":
          message = this.$t("account.fields.avatar.maxSize", {
            size: this.$utils.formatSize(this.upload.size),
          });
          break;
        case "extension":
        case "only csv allowed":
          message = this.$t("account.fields.avatar.extension");
          break;
        case "timeout":
          message = this.$t("upload.errors.timeout");
          break;
        case "abort":
          message = this.$t("upload.errors.abort");
          break;
        case "network":
          message = this.$t("upload.errors.network");
          break;
        case "server":
          message = this.$t("error_codes.error_occured");
          break;
        case "file is required":
          message = this.$t("upload.errors.file_required");
          break;
        case "error_codes.bad_request":
          message = this.$t("error_codes.bad_request");
          break;
        case "error_codes.forbidden":
          message = this.$t("error_codes.forbidden");
          break;
        case "error_codes.error_occured":
          message = this.$t("error_codes.error_occured");
          break;
        default:
          message = file.error;
      }

      return message;
    },
    editAvatar(data) {
      const file = data.file;
      this.$refs.upload.update(data.id, {
        file,
        type: data.file.type,
        size: data.file.size,
        active: true,
      });
    },
    cancelAvatar() {
      this.$refs.upload.clear();
    },
    ...mapActions({ notify: "notification/notify", request: "request" }),
  },
  components: {
    AvatarEdit,
    FileUpload,
  },
  computed: {
    isFileError() {
      return this.files.length && this.files[0].error;
    },
  },
};
</script>
